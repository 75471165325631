<template>
  <div class="edu-counter-area02 ptb-100">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="counter-box02">
          <img src="../../assets/img/icon/counter-1.svg" alt="icon" />
          <h3>82</h3>
          <p>Enrolled Students</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="counter-box02">
          <img src="../../assets/img/icon/counter-2.svg" alt="icon" />
          <h3>460</h3>
          <p>Academic Programs</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12">
        <div class="counter-box02">
          <img src="../../assets/img/icon/counter-3.svg" alt="icon" />
          <h3>20</h3>
          <p>Certified Students</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunFacts",
};
</script>