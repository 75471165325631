<template>
  <div class="event-single-area ptb-100">
    <div class="container">
      <div class="event-single-banner">
        <img src="../../assets/img/all-img/main-event.png" alt="image" />
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="event-single-content">
            <h3>Painting Contest 2023</h3>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour.
            </p>
            <div class="time-counter">
              <ul>
                <li>
                  <span>185</span>
                  <p>Days</p>
                </li>
                <li>
                  <span>6</span>
                  <p>Hours</p>
                </li>
                <li>
                  <span>20</span>
                  <p>Minuets</p>
                </li>
                <li>
                  <span>33</span>
                  <p>Second</p>
                </li>
              </ul>
            </div>
            <p>
              Among the major reasons why Python is “slow”, it really boils down
              to 2 — Python is interpreted as opposed to compiled, ultimately
              leading to slower execution times; and the fact that it is
              dynamically typed. Take, for example, TensorFlow, a Machine
              Learning library available in Python. These libraries were
              actually written in C++ and made available in Python, sort of
              forming a Python implementation. The same goes for Numpy and, to
              an extent, even Caer.
            </p>
            <div class="event-footer-content">
              <div class="row align-items-center">
                <div class="col-lg-6">
                  <div class="pre-btn">
                    <a href="#">Previous</a>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="event-share-link">
                    <ul>
                      <li>
                        <a href="#">
                          <img src="../../assets/img/icon/fb.svg" alt="icon" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="../../assets/img/icon/ins.svg" alt="icon" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="../../assets/img/icon/tw.svg" alt="icon" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img src="../../assets/img/icon/pn.svg" alt="icon" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="event-sidebar-widget">
            <div class="widget widget-details-post">
              <h3 class="widget-title">Event Details</h3>
              <ul>
                <li>
                  <img
                    src="../../assets/img/svg/circle-clock.svg"
                    alt="icon"
                  /><span>4:00 pm - 6:00 pm</span>
                </li>
                <li>
                  <img
                    src="../../assets/img/svg/circle-c.svg"
                    alt="icon"
                  /><span>03 March, 2023</span>
                </li>
                <li>
                  <img
                    src="../../assets/img/svg/circle-clock.svg"
                    alt="icon"
                  /><span>12/A, NewYork Sydney City</span>
                </li>
                <li>
                  <img
                    src="../../assets/img/svg/circle-clock.svg"
                    alt="icon"
                  /><span>yourmail@gmail.com</span>
                </li>
                <li>
                  <img src="../../assets/img/svg/circle-clock.svg" alt="icon" />
                  <span>+88018 2829 98267</span>
                </li>
              </ul>
              <a href="#" class="default-btn">Book A Seat</a>
            </div>
            <div class="widget widget-spacial-gust">
              <h3 class="widget-title">Special Guests</h3>
              <ul>
                <li>
                  <img src="../../assets/img/all-img/rc-1.png" alt="image" />
                  <h4>Sofia D. Flora</h4>
                  <p>UI/UX Designer</p>
                </li>
                <li>
                  <img src="../../assets/img/all-img/rc-2.png" alt="image" />
                  <h4>Jhonson Steven</h4>
                  <p>UI/UX Designer</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventsDetails",
};
</script>