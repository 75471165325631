<template>
  <div class="edu-courses-area pt-70 pb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="single-blog-standard">
                <div class="image">
                  <router-link to="/blog-details" class="d-block">
                    <img
                      src="../../assets/img/all-img/b-thub-1.png"
                      alt="image"
                    />
                  </router-link>
                </div>
                <div class="content">
                  <div class="cr-tag">
                    <a href="#"><span>Development</span></a>
                  </div>
                  <h3>
                    <router-link to="/blog-details">
                     Crafting My Development Portfolio: A Journey of Growth
                    </router-link>
                  </h3>
                  <p>
                    As a developer, creating a portfolio that effectively showcases my skills and projects has always been crucial for advancing my career. Over the past few months, I embarked on the journey of developing my portfolio, and I'd like to share my experience and insights with fellow developers who are on a similar path.
                  </p>
                  <ul class="cr-items">
                    <li>
                      <i class="bx bx-calendar"></i><span>Mar 30, 2024</span>
                    </li>
                    <li>
                      <i class="bx bx-time-five"></i><span>7 Min Read</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="single-blog-standard">
                <div class="image">
                  <router-link to="/blog-details" class="d-block">
                    <img
                      src="../../assets/img/all-img/b-thumb-2.png"
                      alt="image"
                    />
                  </router-link>
                </div>
                <div class="content">
                  <div class="cr-tag">
                    <a href="#"><span>Drawing</span></a>
                  </div>
                  <h3>
                    <router-link to="/blog-details">
                     Capturing Creativity: Building My Drawing Portfolio Online
                    </router-link>
                  </h3>
                  <p>
                   As an aspiring artist, the journey of creating and sharing my drawings online has been both rewarding and enlightening. Today, I want to share my experience with fellow artists who are looking to showcase their artwork effectively through an online portfolio.
                  </p>
                  <ul class="cr-items">
                    <li>
                      <i class="bx bx-calendar"></i><span>Jan 29, 2024</span>
                    </li>
                    <li>
                      <i class="bx bx-time-five"></i><span>5 Min Read</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="single-blog-standard">
                <div class="image">
                  <router-link to="/blog-details" class="d-block">
                    <img
                      src="../../assets/img/all-img/b-thub-3.png"
                      alt="image"
                    />
                  </router-link>
                </div>
                <div class="content">
                  <div class="cr-tag">
                    <a href="#"><span>Technology</span></a>
                  </div>
                  <h3>
                    <router-link to="/blog-details">
                     Shaping Tomorrow: Crafting My Technology Portfolio
                    </router-link>
                  </h3>
                  <p>
                    As a tech enthusiast passionate about innovation and problem-solving, building a compelling portfolio to showcase my projects and expertise has been a crucial step in advancing my career. Today, I want to share my journey of creating a technology portfolio and the insights gained along the way.
                  </p>
                  <ul class="cr-items">
                    <li>
                      <i class="bx bx-calendar"></i><span>Jan 22, 2024</span>
                    </li>
                    <li>
                      <i class="bx bx-time-five"></i><span>5 Min Read</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="pagination-area text-center">
                <a href="#" class="prev page-numbers">
                  <i class="bx bx-chevrons-left"></i>
                </a>
                <span class="page-numbers current" aria-current="page">1</span>
                <a href="#" class="page-numbers">2</a>
                <a href="#" class="page-numbers">3</a>
                <a href="#" class="page-numbers">4</a>
                <a href="#" class="next page-numbers">
                  <i class="bx bx-chevrons-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <SideBar />
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <script lang="ts">
import { defineComponent } from "vue";
import SideBar from "./SideBar.vue";

export default defineComponent({
  name: "BlogStandard",
  components: {
    SideBar,
  },
});
</script> -->