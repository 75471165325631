<template>
  <div class="edu-services-area ptb-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Core Features</p>
        <h2>Why <span class="shape02">Choose</span> Showyourskills</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-layer"></i>
            </div>
            <h3>User-Friendly Experience</h3>
            <p>
              Our platform is designed with ease of use in mind, ensuring that users can use without needing extensive technical skills.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-chalkboard"></i>
            </div>
            <h3>Professional Quality</h3>
            <p>
              We provide a selection of professionally designed templates that ensure every portfolio looks polished and high-end.
            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 col-md-6">
          <div class="edu-services-box">
            <div class="icon">
              <i class="bx bx-support"></i>
            </div>
            <h3>Comprehensive Support</h3>
            <p>
              We offer a range of resources, including tutorials and guides, to help users at every step of the portfolio creation process.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreFeatures",
};
</script>