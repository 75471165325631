<template>
  <div class="edu-topics-area pt-100">
    <div class="container">
      <div class="edu-section-title">
        <p class="sub-title">Start Making Portfolio Today</p>
        <h2>Popular <span class="shape02">Fields</span> To Select</h2>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/illustration.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Graphic Design</h3>
                <p>68 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/design.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Architecture</h3>
                <p>44 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/creative-writing.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Writing and Content Creation</h3>
                <p>65 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-02">
              <div class="icon">
                <img src="../../assets/img/icon/camera.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Photography</h3>
                <p>12 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-03">
              <div class="icon">
                <img src="../../assets/img/icon/animator.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Illustration and Animation</h3>
                <p>33 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-04">
              <div class="icon">
                <img src="../../assets/img/icon/fashion-design.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Fashion Design</h3>
                <p>22 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-05">
              <div class="icon">
                <img src="../../assets/img/icon/shopping-online.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Marketing and Advertising</h3>
                <p>47 Templates</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <a href="#" class="topic-box">
            <div class="single-topic-box lg-01">
              <div class="icon">
                <img src="../../assets/img/icon/video-edition.png" alt="icon" />
              </div>
              <div class="content">
                <h3>Film and Video Production</h3>
                <p>56 Templates</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopularTopics",
};
</script>